import { createRouter, createWebHashHistory } from 'vue-router';
import DefaultPage from '../layouts/default.vue';
import Login from '../layouts/login.vue';
import NotFound from '../layouts/404.vue';

const routes = [
  {
    path: '/',
    component: DefaultPage,
    children: [
      {
        path: '',
        name: 'home',
        component: function () {
          return import('../pages/Index.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/soldproducts',
        name: 'soldproducts',
        component: function () {
          return import('../pages/SoldProducts.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/homeimage',
        name: 'homeimage',
        component: function () {
          return import('../pages/home/Home.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/moderatorAdd',
        name: 'moderatorAdd',
        component: function () {
          return import('../pages/ModeratorAdd.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/sub_title_project',
        name: 'sub_title_project',
        component: function () {
          return import('../pages/sub_title/Project.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/shop_title_project',
        name: 'shop_title_project',
        component: function () {
          return import('../pages/sub_title/Shop.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/serti_title_project',
        name: 'serti_title_project',
        component: function () {
          return import('../pages/sub_title/Serti.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/messanger',
        name: 'messanger',
        component: function () {
          return import('../pages/messanger/Messanger.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/aboutsee',
        name: 'aboutsee',
        component: function () {
          return import('../pages/about/aboutSee.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/aboutadd',
        name: 'aboutadd',
        component: function () {
          return import('../pages/about/aboutAdd.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/aboutedits/:id',
        name: 'aboutedits',
        component: function () {
          return import('../pages/aboutEdit/_id.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/aboutimgedit/:id',
        name: 'aboutimgedit',
        component: function () {
          return import('../pages/aboutImgEdit/_id.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/finishedprojectsmenuadd',
        name: 'finishedprojectsmenuadd',
        component: function () {
          return import(
            '../pages/finishedProjects/fProjectMenuAdd.vue'
          );
        },
        meta: { auth: true },
      },
      {
        path: '/korzinkaprojects',
        name: 'korzinkaprojects',
        component: function () {
          return import('../pages/korzinka/FinishedProjects.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/korzinkamodel',
        name: 'korzinkamodel',
        component: function () {
          return import('../pages/korzinka/Model.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/finishedprojectstypeadd',
        name: 'finishedprojectstypeadd',
        component: function () {
          return import(
            '../pages/finishedProjects/fProjectsTypeAdd.vue'
          );
        },
        meta: { auth: true },
      },
      {
        path: '/finishedprojectsadd',
        name: 'finishedprojectadd',
        component: function () {
          return import('../pages/finishedProjects/fProjectsAdd.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/finishedprojectsflooradd',
        name: 'finishedprojectsflooradd',
        component: function () {
          return import(
            '../pages/finishedProjects/fProjectFloorAdd.vue'
          );
        },
        meta: { auth: true },
      },
      {
        path: '/finishedprojectsroomadd',
        name: 'finishedprojectsroomadd',
        component: function () {
          return import(
            '../pages/finishedProjects/fProjectRoomAdd.vue'
          );
        },
        meta: { auth: true },
      },
      {
        path: '/finishedprojectsee',
        name: 'finishedprojectsee',
        component: function () {
          return import('../pages/finishedProjects/fProjectsSee.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/fprojectedit/:id',
        name: 'fprojectedit',
        component: function () {
          return import('../pages/fProjectEdit/_id.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/typeput/:id',
        name: 'typeput',
        component: function () {
          return import('../pages/finishedProjects/TypePut.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/fprojectroom/:id',
        name: 'fprojectroom',
        component: function () {
          return import('../pages/fProjectRoom/_id.vue');
        },
        meta: { auth: true },
      },

      {
        path: '/menuEdit/:id',
        name: 'menuEdit',
        component: function () {
          return import(
            '../pages/finishedProjects/projectMenuEdit/_id.vue'
          );
        },
        meta: { auth: true },
      },

      {
        path: '/constractionmenuadd',
        name: 'constractionmenuadd',
        component: function () {
          return import(
            '../pages/constraction/constractionMenuAdd.vue'
          );
        },
        meta: { auth: true },
      },
      {
        path: '/constractiontypeadd',
        name: 'constractiontypeadd',
        component: function () {
          return import(
            '../pages/constraction/constractionTypeAdd.vue'
          );
        },
        meta: { auth: true },
      },
      {
        path: '/constractionadd',
        name: 'constractionadd',
        component: function () {
          return import('../pages/constraction/constractionAdd.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/constractionsee',
        name: 'constractionsee',
        component: function () {
          return import('../pages/constraction/constractionSee.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/constraction/:id',
        name: 'constraction',
        component: function () {
          return import('../pages/constractionSee/_id.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/constractionedit/:id',
        name: 'constractionedit',
        component: function () {
          return import('../pages/constractionEdit/_id.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/consmenuedit/:id',
        name: 'consmenuedit',
        component: function () {
          return import('../pages/constraction/consMenuEdit.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/constypeedit/:id',
        name: 'constypeedit',
        component: function () {
          return import('../pages/constraction/consTypeEdit.vue');
        },
        meta: { auth: true },
      },
      // model
      {
        path: '/modelmenuadd',
        name: 'modelmenuadd',
        component: function () {
          return import('../pages/model/modelMenuAdd.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/modeltypeadd',
        name: 'modeltypeadd',
        component: function () {
          return import('../pages/model/modelTypeAdd.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/modeladd',
        name: 'modeladd',
        component: function () {
          return import('../pages/model/modelAdd.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/modelsee',
        name: 'modelsee',
        component: function () {
          return import('../pages/model/modelSee.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/model/:id',
        name: 'model',
        component: function () {
          return import('../pages/modelSee/_id.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/teamadd',
        name: 'teamadd',
        component: function () {
          return import('../pages/team/teamAdd.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/teamsee',
        name: 'teamsee',
        component: function () {
          return import('../pages/team/teamSee.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/teamedit/:id',
        name: 'teamedit',
        component: function () {
          return import('../pages/teamEdit/_id.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/teamimgedit/:id',
        name: 'teamimgedit',
        component: function () {
          return import('../pages/teamImgEdit/_id.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/directoradd',
        name: 'directoradd',
        component: function () {
          return import('../pages/director/directorAdd.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/directorsee',
        name: 'directorsee',
        component: function () {
          return import('../pages/director/directorSee.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/directoredit/:id',
        name: 'directoredit',
        component: function () {
          return import('../pages/directorEdit/_id.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/directorimgedit/:id',
        name: 'directorimgedit',
        component: function () {
          return import('../pages/directorImgEdit/_id.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/sertificatsee',
        name: 'sertificatsee',
        component: function () {
          return import('../pages/sertificat/sertiSee.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/sertificatadd',
        name: 'sertificatadd',
        component: function () {
          return import('../pages/sertificat/sertiAdd.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/sertificatedit/:id',
        name: 'sertificatedit',
        component: function () {
          return import('../pages/sertificatEdit/_id.vue');
        },
        meta: { auth: true },
      },
      {
        path: '/messages',
        name: 'messages',
        component: function () {
          return import('../pages/message/message.vue');
        },
        meta: { auth: true },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const reqAuth = to.meta.auth;
  const token = localStorage.getItem('tokenn');

  if (reqAuth && token) {
    if (to.name == 'login') {
      next({ name: 'home' });
      return;
    }

    next();
    return;
  }

  if (token) {
    if (to.name == 'login') {
      next({ name: 'home' });
      return;
    }
  } else {
    if (to.name != 'login') {
      next({ name: 'login' });
      return;
    }
  }

  next();
});

export default router;
