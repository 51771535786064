<template>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-8">
        <h1 class="text-center fs-3 fw-bolder">
          Kirish
          <span class="text-primary">adminbro.bimproject.uz</span>
        </h1>

        <form @submit.prevent>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label"
              >Phone</label
            >
            <input
              type="number"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Telefon"
              v-model="data.phone"
              :class="!phoneIsValid ? 'border-danger' : ''"
            />
          </div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label"
              >Parol</label
            >
            <input
              type="password"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Parol "
              v-model="data.password"
              :class="!passwordIsValid ? 'border-danger' : ''"
            />
          </div>
          <button
            class="btn btn-primary"
            :disabled="!formIsValid"
            @click="login"
          >
            Kirish
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        phone: null,
        password: null,
      },
      xato: null,
    };
  },
  computed: {
    phoneIsValid() {
      return !!this.data.phone;
    },
    passwordIsValid() {
      return !!this.data.password;
    },

    formIsValid() {
      return this.phoneIsValid && this.passwordIsValid;
    },
  },
  methods: {
    login() {
      // console.log(this.data);
      this.axios
        .post('https://api.bimproject.uz/admin/login', this.data)
        .then((res) => {
          if (res.data.success) {
            let token = res.data.token;
            let data = res.data.data;
            localStorage.setItem('tokenn', token);
            localStorage.setItem('phonen', data.phone);
            localStorage.setItem('usern', data.name);
            localStorage.setItem('rolen', data.role);
            this.$router.push({
              name: 'home',
            });
          } else {
            this.$router.push({
              name: 'login',
            });
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  mounted(){
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    localStorage.removeItem("role")
    localStorage.removeItem("phone")
  }
};
</script>

<style scoped></style>
