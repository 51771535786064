<template>
  <div>
    <nav>
      <div class="nav-wrapper">
        <router-link v-if="role == 'admin'" to="/moderatorAdd" class="btn btn-warning me-3">
          Moderator qo'shish
        </router-link>
        <h4 class="me-2">{{ role }}</h4>
        <h4 class="me-2">{{ user }}</h4>
        <button class="btn btn-danger" @click="logout">
          <i class="fa-solid fa-arrow-right-from-bracket"></i> Chiqish
        </button>
        <div class="bars ps-3">
          <i class="fa-solid fa-bars"></i>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      role: '',
      user: '',
    };
  },
  created() {
    this.role = localStorage.getItem('rolen');
    this.user = localStorage.getItem('usern');
  },
  methods: {
    logout() {
      localStorage.removeItem('tokenn');
      localStorage.removeItem('rolen');
      localStorage.removeItem('usern');
      localStorage.removeItem('phonen');
      window.location.reload();
    },
  },
};
</script>

<style scoped>
nav {
  width: 100%;
}
.nav-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 15px;
  background-color: #11101d;
}
.nav-wrapper h4 {
  font-family: 'Poppins';
  font-size: 16px;
  text-transform: capitalize;
  color: white;
}
.bars {
  cursor: pointer;
  display: none;
}
.bars i {
  color: white;
  /* display: none; */

  font-size: 20px;
}
/* media  */
@media (max-width: 1200px) {
  .bars {
    display: block;
  }
}
</style>
